import CookieConsent from 'react-cookie-consent'

import { theme } from '@/styles/theme'

import { LinkText } from '../LinkText'

export const AcceptCookiePopup = () => {
  return (
    <CookieConsent
      location="bottom"
      enableDeclineButton
      buttonText="同意する"
      declineButtonText="同意しない"
      cookieName="cookieAccept"
      cookieValue="true"
      style={{
        background: '#fff',
        maxWidth: '1000px',
        left: '50%',
        transform: 'translateX(-50%)',
        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.25)',
        alignItems: 'center',
        color: theme.colors.textTitle,
        lineHeight: 1.8,
      }}
      buttonStyle={{
        background: theme.colors.trialBlue,
        color: '#fff',
        borderRadius: '4px',
      }}
      declineButtonStyle={{
        background: '#fff',
        color: theme.colors.trialBlue,
        border: `1px solid ${theme.colors.trialBlue}`,
        borderRadius: '4px',
      }}
      expires={365}
    >
      このウェブサイトでは、サイトの利便性の向上を目的にCookieを取得します。取得にご同意いただける場合は「同意する」ボタンをクリックしてください。詳細は個人情報保護方針の
      <LinkText
        path="/legal/privacy-policy/#privacy7"
        text="「7. Cookieの利用」"
      />
      をご覧ください。
    </CookieConsent>
  )
}
