import Link from 'next/link'
import { HTMLAttributeAnchorTarget } from 'react'
import styled from 'styled-components'

import { theme } from '@/styles/theme'

type Props = {
  path: string
  text: string
  target?: HTMLAttributeAnchorTarget
}

export const LinkText = ({ path, text, target }: Props) => {
  return (
    <Link href={path} passHref>
      <StyledLink target={target ?? '_self'}>{text}</StyledLink>
    </Link>
  )
}

const StyledLink = styled.a`
  color: ${theme.colors.trialBlue};
  transition: color 0.3s ease-out;
  text-decoration: underline;
  &:hover {
    color: ${theme.colors.hoverLightBlue};
  }
`
