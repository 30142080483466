import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { ContactMedia } from './types'

const initialState: ContactMedia = {
  company: '',
  lastName: '',
  firstName: '',
  email: '',
  tel: '',
  media: '',
  title: '',
  detail: '',
}

export const contactMediaSlice = createSlice({
  name: 'contactMedia',
  initialState,
  reducers: {
    setContactMedia: (_, action: PayloadAction<ContactMedia>) => {
      return {
        ...action.payload,
      }
    },
    clearState: () => {
      return {
        ...initialState,
      }
    },
  },
})

// action
export const { setContactMedia, clearState } = contactMediaSlice.actions

// reducer
export const contactMediaReducer = contactMediaSlice.reducer
