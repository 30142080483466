import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { ContactIr } from './types'

const initialState: ContactIr = {
  company: '',
  lastName: '',
  firstName: '',
  email: '',
  tel: '',
  media: '',
  title: '',
  detail: '',
}

export const contactIrSlice = createSlice({
  name: 'contactIr',
  initialState,
  reducers: {
    setContactIr: (_, action: PayloadAction<ContactIr>) => {
      return {
        ...action.payload,
      }
    },
    clearState: () => {
      return {
        ...initialState,
      }
    },
  },
})

// action
export const { setContactIr, clearState } = contactIrSlice.actions

// reducer
export const contactIrReducer = contactIrSlice.reducer
