const containerMargin = '24px'
const containerWidth = '1000px'

export const theme = {
  colors: {
    green: '#57AC2B',
    purple: '#D382D9',
    red: '#C41E1E',
    grey: '#B5BDC4',
    backgroundGrey: '#F9F9F9',
    brown: '#AC752B',
    trialBlue: '#002D65',
    hoverLightBlue: '#3786c7',
    turquoiseBlue: '#2B84AC',
    backgroundBlue: '#E1E8EF',
    lightBlue: '#4D84C2',
    textTitle: '#3A3C3E',
    textSecond: '#707070',
    overlay: '#3A3C3E',
    hover: '#3786C7',
    backgroundPaleBlue: '#e5f5fc',
    tableBorder: '#88A0B7',
    th: {
      default: '#EEF3F9',
      darkest: '#94B5DA',
      primary: '#AFC7E3',
      secondary: '#D2E0EF',
      tertiary: '#EEF3F9',
      quaternary: '#FFFFFF',
      accentYellow: '#F6ECD3',
      holiday: '#C8C8C8',
    },
    td: {
      default: '#FFFFFF',
    },
    chart: {
      owner: {
        bank: '#3783C4',
        financialInstrumentsDealer: '#569CD8',
        otherCorporation: '#449330',
        foreignCorporation: '#6BA65D',
        individual: '#8DBF80',
        treasuryStock: '#002D65',
      },
    },
  },
  lineGraph: '#4D84C2',
  priceSubColor: '#EDF3F9',
  breakPoint: `calc(${containerWidth} + ${containerMargin} * 2)`,
  fontEn: 'Rubik, "Noto Sans JP", Arial, sans-serif',
} as const
