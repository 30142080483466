import { createGlobalStyle } from 'styled-components'
import reset from 'styled-reset'

import { theme } from './theme'

const GlobalStyle = createGlobalStyle`body {
    font-family: "Noto Sans JP", Arial, sans-serif !important;
    color: ${theme.colors.textTitle};
  }

  * {
    box-sizing: border-box;
  }

  a {
    text-decoration: none;
    color: ${theme.colors.textTitle};
    cursor: pointer;
  }

  button {
    font-family: inherit;
    cursor: pointer;
  }

  @font-face {
    font-family: Rubik;
    font-display: swap;
    src: url("/fonts/Rubik-Medium.ttf");
    src: url("/fonts/Rubik-Bold.ttf");
  }

  ${reset}
`

export default GlobalStyle
