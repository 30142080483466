import { configureStore } from '@reduxjs/toolkit'
import {
  useSelector as rawUseSelector,
  TypedUseSelectorHook,
  useDispatch as rawUseDispatch,
} from 'react-redux'

import { contactCorporationReducer } from './contactCorporation/slice'
import { contactIrReducer } from './contactIr/slice'
import { contactMediaReducer } from './contactMedia/slice'

export const store = configureStore({
  reducer: {
    contactMedia: contactMediaReducer,
    contactCorporation: contactCorporationReducer,
    contactIr: contactIrReducer,
  },
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export const useSelector: TypedUseSelectorHook<RootState> = rawUseSelector
export const useDispatch: () => AppDispatch = rawUseDispatch
