import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { ContactCorporation } from './types'

const initialState: ContactCorporation = {
  company: '',
  lastName: '',
  firstName: '',
  email: '',
  tel: '',
  title: '',
  detail: '',
}

export const contactCorporationSlice = createSlice({
  name: 'contactCorporation',
  initialState,
  reducers: {
    setContactCorporation: (_, action: PayloadAction<ContactCorporation>) => {
      return {
        ...action.payload,
      }
    },
    clearState: () => {
      return {
        ...initialState,
      }
    },
  },
})

// action
export const { setContactCorporation, clearState } =
  contactCorporationSlice.actions

// reducer
export const contactCorporationReducer = contactCorporationSlice.reducer
