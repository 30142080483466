import type { NextPage } from 'next'
import type { AppProps } from 'next/app'
import type { ReactElement } from 'react'
import { Provider } from 'react-redux'
import { ThemeProvider } from 'styled-components'

import { store } from '@/store'
import GlobalStyle from '@/styles/global'
import { theme } from '@/styles/theme'

import { AcceptCookiePopup } from '@/components/elements/AcceptCookiePopup'
import { GoogleTagManager } from '@/components/elements/GoogleTagManager'

type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactElement
}

type AppPropsWithLayout<P = {}> = AppProps<P> & {
  Component: NextPageWithLayout<P>
}

export default function App({ Component, pageProps }: AppPropsWithLayout) {
  const getLayout = Component.getLayout || ((page) => page)
  const isProduction = process.env.NODE_ENV === 'production'

  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        {/* NODE_ENVが’production’の時のみ表示する */}
        {isProduction && <GoogleTagManager />}
        {getLayout(<Component {...pageProps} />)}
        <AcceptCookiePopup />
      </ThemeProvider>
    </Provider>
  )
}
